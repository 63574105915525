import React, { useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import Lottie from 'react-lottie';

import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';

import { Container, Content } from './styles';
import Input from '~/components/Input';
import animation from '~/assets/animations/login.json';
import logo from '~/assets/logos/logo.png';

interface IFormData {
  email: string;
}

const ResetPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const handleSubmit = useCallback(async (data: IFormData) => {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        email: Yup.string()
          .email('Correo no válido')
          .required('Correo requerido'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await api.post('students-manager/forgot-password', { email: data.email });

      Swal.fire(
        'Atención',
        'Se ha enviado un correo electrónico para restablecer su contraseña.',
        'info'
      );
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErros(error);
        formRef.current?.setErrors(errors);
      } else {
        Swal.fire(
          'Opss...',
          'Se ha producido un error, compruebe sus datos.',
          'error'
        );
      }
    }
  }, []);

  return (
    <Container className="container-fluid">
      <div className="row">
        <div className="col-lg-5 d-none d-lg-flex flex-column justify-content-center px-0">
          <Lottie options={defaultOptions} height={400} width={400} />
        </div>

        <Content className="col-lg-7 d-flex flex-column justify-content-center align-items-center">
          <div className="row w-100">
            <div className="d-flex mt-2 mb-5 flex-row-reverse justify-content-center">
              <img src={logo} alt="logo" className="logo" />
            </div>
          </div>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            className="px-3 px-sm-4 px-md-5 px-lg-0"
          >
            <div>
              <h1 className="fw-bold mb-3 h2">Recuperar la contraseña</h1>
              <p className="mb-5">
                Por favor, introduzca su correo electrónico
              </p>
            </div>
            <div className="mb-5">
              <label htmlFor="email" className="mb-2 fw-medium">
                Correo electrónico
              </label>
              <Input
                type="email"
                placeholder="email@example.com"
                name="email"
              />
            </div>
            <div className="mb-3">
              <button
                type="submit"
                className="btn btn-submit mb-2 w-100 fw-bold py-3"
              >
                Enviar
              </button>
            </div>
            <div className="d-flex px-5 pb-5 justify-content-center align-items-center">
              <img src={logo} alt="logo" className="logo" />
            </div>
          </Form>
        </Content>
      </div>
    </Container>
  );
};

export default ResetPassword;
