import React from 'react';

import { BsCheckCircle } from 'react-icons/bs';
import { BiBlock } from 'react-icons/bi';

import { PanelData } from './styles';

export interface ICardChecklistResponse {
  id: number;
  success: boolean;
  message: string;
}

interface ICardChecklist {
  data: ICardChecklistResponse[];
}

const CardChecklist: React.FC<ICardChecklist> = ({ data }) => {
  return (
    <PanelData className="d-flex justify-content-center row p-3 mx-0 my-2">
      <div className="row mb-3">
        <div className="d-flex">
          <h5 className="text-dark">Revisar los requisitos para sacar turno</h5>
        </div>
      </div>

      {data.map((item) => (
        <div className="d-flex mb-2" key={item.message}>
          <div className="">
            {item.success === true ? (
              <BsCheckCircle className="me-2" size={20} color="#4CAF50" />
            ) : (
              <BiBlock className="me-2" size={20} color="#FC5D4A" />
            )}
          </div>
          <div className="">
            <h6 className="text-dark">{item.message}</h6>
          </div>
        </div>
      ))}
    </PanelData>
  );
};

export default CardChecklist;
