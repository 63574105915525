import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface ILoading {
  active?: boolean;
  fixed?: boolean;
}

export const Container = styled.div`
  background-color: #ffffff;

  > div {
    min-height: 100vh;
  }

  .position-absolute {
    top: 20px;
    right: 20px;
    min-height: auto;
  }

  .logo {
    width: 100px;
  }
`;

export const Content = styled.div`
  h1 {
    color: #31344e;
  }

  p {
    color: #8692a6;
  }

  a {
    color: #8692a6;
  }

  label {
    color: #696f79;
  }

  .btn-submit {
    color: #ffffff;
    background: #cb9614;
    border-radius: 10px;
  }

  form {
    width: 100%;
  }

  @media screen and (min-width: 992px) {
    form {
      width: 75%;
    }
  }

  @media screen and (min-width: 1200px) {
    form {
      width: 60%;
    }
  }

  @media screen and (min-width: 1600px) {
    form {
      width: 45%;
    }
  }

  @media screen and (min-width: 2000px) {
    form {
      width: 35%;
    }
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    height: 400px;

    .btn-submit {
      color: #ffffff;
      background: #2e90fd;
      border-radius: 10px;
    }
  }
`;

export const Loading = styled.div<ILoading>`
  transition-duration: 0.3s;
  opacity: ${(props) => (props.active ? 1 : 0)};
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  position: ${(props) => (props.fixed ? 'fixed' : 'absolute')};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  span {
    color: #fff !important;
  }
`;
