import styled from 'styled-components';

export const PanelMensage = styled.div`
  .mensage-red {
    color: #f4000f;
  }

  .mensage-green {
    color: #4caf50;
  }
`;
