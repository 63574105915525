import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Container = styled.div`
  background-color: #ffffff;

  > div {
    min-height: 100vh;
  }

  .position-absolute {
    top: 20px;
    right: 20px;
    min-height: auto;
  }
`;

export const Content = styled.div`
  h1 {
    color: #31344e;
  }

  h6 {
    color: #8692a6;
  }

  a {
    color: #166b9a;
    font-weight: 600;
    font-size: 12px;
  }

  label {
    color: #696f79;
  }

  .btn-submit {
    color: #ffffff;
    background: #cb9614;
    border-radius: 10px;
  }

  .btn-edit {
    background: #cb9614;
    color: #ffffff;
    border-radius: 50%;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    border-bottom: 3px solid #cb9614;
    border-left: transparent;
    border-right: transparent;
    border-top: transparent;
    cursor: default;
    color: #cb9614;
  }

  .nav-personalizate {
    display: flex;
  }

  .button-personalizate {
    margin-bottom: 100px;
  }

  .logo {
    width: 7rem;
  }

  @media screen and (min-width: 992px) {
    form {
      width: 75%;
    }
  }

  @media screen and (min-width: 1200px) {
    form {
      // width: 60%;
    }
  }

  @media screen and (min-width: 1600px) {
    form {
      // width: 45%;
    }
  }

  @media screen and (min-width: 2000px) {
    form {
      // width: 35%;
    }
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    height: 400px;

    .btn-submit {
      color: #ffffff;
      background: #2e90fd;
      border-radius: 10px;
    }
  }
`;
