import React from 'react';
import { GiArchiveRegister } from 'react-icons/gi';

import { Button } from './styles';

interface IButtonScriptionProps {
  title: string;
  type: 'button' | 'submit' | 'reset' | undefined;
  name?: string;
  onClick?: () => void;
}

const ButtonInscription: React.FC<IButtonScriptionProps> = ({
  title,
  onClick,
  type,
  name,
}) => {
  return (
    <Button type={type} className="h5" name={name} onClick={onClick}>
      <GiArchiveRegister size={25} className="me-3" />
      {title}
    </Button>
  );
};

export default ButtonInscription;
