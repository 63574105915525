import React, { useCallback, useEffect, useState } from 'react';

import { Container, RadioInput } from './styles';
import Input from '~/components/Input';

export interface IOption {
  value: string | number;
  label: string;
}

interface IInputRadio {
  name: string;
  options: IOption[];
  className?: string;
  onChange?(value: IOption): void;
  selected?: IOption;
}

const InputRadio: React.FC<IInputRadio> = ({
  name,
  options,
  className,
  selected,
  onChange,
}) => {
  const [valueSelected, setValueSelected] = useState<string | number>('');

  useEffect(() => {
    if (selected) {
      setValueSelected(selected.value);
    }
  }, [selected]);

  const handleChange = useCallback(
    (value) => {
      setValueSelected(value.value);
      if (onChange) {
        onChange(value);
      }
    },
    [onChange]
  );

  return (
    <>
      <Container className={className}>
        {options.map((option, index) => (
          <RadioInput
            htmlFor={`${name}_${index}`}
            selected={valueSelected === option.value}
          >
            <div />
            <input
              type="radio"
              id={`${name}_${index}`}
              name={`${name}_radio`}
              value={option.value}
              onChange={() => handleChange(option)}
            />
            <span>{option.label}</span>
          </RadioInput>
        ))}
      </Container>
      <Input name={name} value={valueSelected} className="d-none" />
    </>
  );
};

export default InputRadio;
