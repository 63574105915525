import React from 'react';

import ButtonInscription from '~/components/ButtonInscription';

import { PanelData, PanelScroll } from './styles';

interface ICardItemsProps {
  cardTitle: string;
  buttonTitle?: string;
  buttonClick?: () => void;
  children?: React.ReactNode;
  isActive?: boolean;
}

const CardItems: React.FC<ICardItemsProps> = ({
  cardTitle,
  buttonTitle,
  buttonClick,
  children,
  isActive,
}) => {
  return (
    <PanelData className="d-flex justify-content-center row p-3 my-2">
      <div className="col-md-6 d-flex align-items-center justify-content-left">
        <div className="d-flex">
          <h5 className="text-dark">{cardTitle}</h5>
        </div>
      </div>
      <div className="col-md-6 d-flex button-position">
        {buttonTitle && isActive && (
          <ButtonInscription
            title={buttonTitle}
            onClick={buttonClick}
            type="button"
          />
        )}
      </div>

      <div className="row p-0">
        {cardTitle === 'Período de inscripción' && (
          <div className="border border-inscripicion ">{children}</div>
        )}
        {cardTitle === 'Fechas de entrega' && (
          <PanelScroll>{children}</PanelScroll>
        )}
      </div>
    </PanelData>
  );
};

export default CardItems;
