import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Login from '~/pages/Login';
import ForgotPassword from '~/pages/ForgotPassword';
import ResetPassword from '~/pages/ResetPassword';
import ConfirmMail from '~/pages/ConfirmMail';
import Register from '~/pages/Register';
import Students from '~/pages/Students';

const routes: React.FC = () => {
  return (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/`} exact component={Login} />
      <Route
        path={`${process.env.PUBLIC_URL}/forgot-password`}
        exact
        component={ForgotPassword}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/reset-password/:token`}
        exact
        component={ResetPassword}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/confirm-email/:email/:token`}
        exact
        component={ConfirmMail}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/register`}
        exact
        component={Register}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/students`}
        isPrivate
        exact
        component={Students}
      />
    </Switch>
  );
};

export default routes;
