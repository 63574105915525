import React, { useCallback, useEffect, useState } from 'react';

import { format, parseISO } from 'date-fns';
import api from '~/services/api';

import CardItem from '../CardItems';

import { PanelMensage } from './styles';
import Loading from '~/components/Loading';

export interface IStudentInscriptionProps {
  inscription_begin: string;
  inscription_end: string;
  basket_limit: string;
  enrolled_at: string;
  approved: number | null;
  days_start: number;
  days_end: number;
  enrolled_id: number;
}

export interface IDatesProps {
  start: number;
  end: number;
}

interface ICardStudentData {
  onClick: () => void;
  onhandLoadId_enrolled?(id_enrollement: number): void;
  onhandLoadDates?({ start, end }: IDatesProps): void;
}

const CardEnrollmentPeriod: React.FC<ICardStudentData> = ({
  onClick,
  onhandLoadId_enrolled,
  onhandLoadDates,
}) => {
  const [enrollmentPeriod, setEnrollmentPeriod] =
    useState<IStudentInscriptionProps>({} as IStudentInscriptionProps);
  const [loading, setLoading] = useState<boolean>(false);

  const fillingEnrollmentPeriod = useCallback(async () => {
    setLoading(true);
    await api
      .get<IStudentInscriptionProps>(`students-manager/enrollment-periods`)
      .then((responseEnrollment) => {
        const {
          approved,
          basket_limit,
          days_end,
          days_start,
          enrolled_at,
          inscription_begin,
          inscription_end,
          enrolled_id,
        } = responseEnrollment.data;

        const newData = {
          approved,
          basket_limit: format(parseISO(basket_limit), 'dd/MM/yyyy'),
          days_end,
          days_start,
          enrolled_at: enrolled_at
            ? format(parseISO(enrolled_at), 'dd/MM/yyyy HH:mm')
            : '',
          inscription_begin: format(parseISO(inscription_begin), 'dd/MM/yyyy'),
          inscription_end: format(parseISO(inscription_end), 'dd/MM/yyyy'),
          enrolled_id,
        };

        if (onhandLoadId_enrolled) {
          onhandLoadId_enrolled(enrolled_id);
        }

        if (onhandLoadDates) {
          onhandLoadDates({
            start: enrollmentPeriod.days_start,
            end: enrollmentPeriod.days_end,
          });
        }

        setEnrollmentPeriod(newData);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    enrollmentPeriod.days_end,
    enrollmentPeriod.days_start,
    onhandLoadDates,
    onhandLoadId_enrolled,
  ]);

  useEffect(() => {
    fillingEnrollmentPeriod();
  }, [fillingEnrollmentPeriod]);

  return (
    <>
      <CardItem
        cardTitle="Período de inscripción"
        buttonTitle="Inscribirse en ASA"
        buttonClick={onClick}
        isActive={
          enrollmentPeriod.enrolled_id === null &&
          enrollmentPeriod.days_start >= 0 &&
          enrollmentPeriod.days_end <= 0
        }
      >
        {enrollmentPeriod.inscription_begin ? (
          <div className="row">
            <div className="col-md-2 p-2">
              <div className="d-flex flex-column">
                <span className="h6 pb-1">Inicio</span>
                <span className="h6 text-dark">
                  {enrollmentPeriod.inscription_begin}
                </span>
              </div>
            </div>

            <div className="col-md-2 p-2">
              <div className="d-flex flex-column">
                <span className="h6 pb-1">Término</span>
                <span className="h6 text-dark">
                  {enrollmentPeriod.inscription_end}
                </span>
              </div>
            </div>

            <div className="col-md-4 p-2">
              <div className="d-flex flex-column">
                <span className="h6 pb-1">Limite para sacar el bolsón</span>
                <span className="h6 text-dark">
                  {enrollmentPeriod.basket_limit}
                </span>
              </div>
            </div>

            <div className="col-md-4 p-2">
              <div className="d-flex flex-column">
                <span className="h6 pb-1">Inscripto en</span>
                <span className="h6 text-dark">
                  {enrollmentPeriod.enrolled_at}
                </span>
              </div>
            </div>

            {enrollmentPeriod.enrolled_id && (
              <div className="row mt-1">
                {enrollmentPeriod.approved === null && (
                  <PanelMensage className="d-flex justify-content-center">
                    <span className="h6 mensage-red">
                      Te encuentras inscripto, PERO AÚN NO HA SIDO EVALUADO POR
                      ASA
                    </span>
                  </PanelMensage>
                )}

                {enrollmentPeriod.approved === 1 && (
                  <PanelMensage className="d-flex justify-content-center">
                    <span className="h6 mensage-green">
                      Ha sido evaluado y estas APROBADO para obtener TURNO
                      ONLINE PARA BOLSÓN DE ALIMENTOS.
                    </span>
                  </PanelMensage>
                )}

                {enrollmentPeriod.approved === 0 && (
                  <PanelMensage className="d-flex justify-content-center">
                    <span className="h6 mensage-green">
                      Ha sido evaluado y estas APROBADO para obtener TURNO
                      ONLINE PARA GALLETAS Y CEREALES.
                    </span>
                  </PanelMensage>
                )}
              </div>
            )}
          </div>
        ) : (
          <h5 className="py-2">Ningún período disponible.</h5>
        )}
      </CardItem>
      <Loading show={loading} message="Cargando datos del servidor..." />
    </>
  );
};

export default CardEnrollmentPeriod;
