import styled from 'styled-components';

interface ICheckboxInput {
  selected?: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const CheckboxInput = styled.label<ICheckboxInput>`
  display: flex;
  align-items: center;

  span {
    color: #828282;
    font-weight: 500;
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    margin-right: 8px;
    transition-duration: 0.3s;
    background: ${(props) => (props.selected ? '#36c5ef' : '#e4f0f9')};
    border: ${(props) =>
      props.selected
        ? '1px solid #36c5ef'
        : '1px solid rgba(39, 162, 254, 0.24)'};
  }

  input {
    display: none;
  }

  + label {
    margin-left: 20px;
  }
`;
