import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Modal = styled(ModalComponent)`
  .modal-content {
    background: #ffffff;
  }

  .btn-position {
    flex-direction: row-reverse;

    @media (max-width: 1024px) {
      flex-direction: none;
      justify-content: center;
    }
  }

  .btn-submit {
    color: #ffffff;
    background: #2e90fd;
    border-radius: 40px;
  }
`;
