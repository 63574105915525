import React, { useState, useEffect, useCallback } from 'react';
import Swal from 'sweetalert2';
import { ImExit } from 'react-icons/im';
import { MdEmail } from 'react-icons/md';

import { useAuth } from '~/hooks/Auth';

import api from '~/services/api';

import { PanelLogo, FooterContainer } from './styles';
import logo from '~/assets/logos/logo.png';

import CardDelivery from './Partials/Cards/CardDelivery';
import CardStudent from './Partials/Cards/CardStudent';
import CardChecklist, {
  ICardChecklistResponse,
} from './Partials/Cards/CardChecklist';
import ModalQuiz from './Partials/Modals/ModalQuiz/indext';
import CardEnrollmentPeriod, {
  IDatesProps,
} from './Partials/Cards/CardEnrollmentPeriod';

const Students: React.FC = () => {
  const { signOut } = useAuth();
  const [showModalQuiz, setShowModalQuiz] = useState(false);
  const { user, updateUser } = useAuth();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [checklist, setChecklist] = useState<ICardChecklistResponse[]>([]);
  const [enrolledId, setEnrolledId] = useState(0);
  const [datesEnroled, setDatesEnroled] = useState<IDatesProps>(
    {} as IDatesProps
  );

  const handleScroll = useCallback(() => {
    setScrollPosition(window.scrollY);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    api
      .get('students-manager/get-access')
      .then((response) => {
        updateUser(response.data);
      })
      .catch(() => {
        signOut();
        localStorage.removeItem('@ASASTUDENTS:token');
        localStorage.removeItem('@ASASTUDENTS:user');
      });
  }, [signOut, updateUser]);

  const fillingChecklist = useCallback(async () => {
    const responseChecklist = await api.get<ICardChecklistResponse[]>(
      `students-manager/checklist/`
    );
    setChecklist(responseChecklist.data);
  }, []);

  useEffect(() => {
    fillingChecklist();
  }, [fillingChecklist]);

  const handleShowQuiz = () => {
    if (datesEnroled.start >= 0 && datesEnroled.end <= 0) {
      setShowModalQuiz(true);
    } else {
      Swal.fire({
        title: 'Oops...',
        text: 'El período de inscripción aún no se ha liberado, espere a que finalice el período actual',
        showDenyButton: true,
        confirmButtonText: 'Correcto',
        icon: 'info',
        showCancelButton: false,
      });
    }
  };

  const handLoadId_enrolled = useCallback(
    (id_enrolled: number) => {
      if (id_enrolled <= 0 || id_enrolled !== enrolledId) {
        setEnrolledId(id_enrolled);
      }
    },
    [enrolledId]
  );

  const handLoadDatesEnroled = useCallback(({ start, end }: IDatesProps) => {
    if (start || end) {
      setDatesEnroled({ start, end });
    }
  }, []);

  return (
    <>
      <div className="row">
        {scrollPosition >= 2 ? (
          <PanelLogo className="row p-0 m-0 fixed-top">
            <div className="col-md-6 d-flex align-items-center">
              <img src={logo} alt="logo" className="logo ms-2" />
              <b className="h4 ms-4 mt-3 title-logo">
                Sistema ASA UAP - Alumnos
              </b>
            </div>

            <div className="d-flex align-items-center col-md-6 justify-content-end mt-2">
              <div className="d-flex align-items-center mx-3 mt-2">
                <h6 className="menu-items">Hola,</h6>
                <h6 className="text-dark ms-2 menu-items">{user.name}</h6>
              </div>
              <div className="d-flex flex-row">
                <button
                  type="button"
                  className="border-none"
                  onClick={() => signOut()}
                >
                  <ImExit size={22} className="text-dark mx-2" />
                </button>

                {/* <button
                    type="button"
                    className="border-none"
                    // onClick={() => setShow(true)}
                  >
                    <BiBell size={22} className="text-dark mx-2" />
                  </button> */}

                <a
                  href="mailto:asaalimentosuap@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <MdEmail size={22} className="text-dark mx-2" />
                </a>
              </div>
            </div>
          </PanelLogo>
        ) : (
          <PanelLogo className="row p-0 m-0 row">
            <div className="col-md-6 d-flex align-items-center">
              <img src={logo} alt="logo" className="logo ms-2" />
              <b className="h4 ms-4 mt-3 title-logo">
                Sistema ASA UAP - Alumnos
              </b>
            </div>

            <div className="d-flex align-items-center col-md-6 justify-content-end mt-2">
              <div className="d-flex align-items-center mx-3 mt-2">
                <h6 className="menu-items">Hola,</h6>
                <h6 className="text-dark ms-2 menu-items">{user.name}</h6>
              </div>
              <div className="d-flex flex-row">
                <button
                  type="button"
                  className="border-none"
                  onClick={() => signOut()}
                >
                  <ImExit size={22} className="text-dark mx-2" />
                </button>

                {/* <button
                    type="button"
                    className="border-none"
                    // onClick={() => setShow(true)}
                  >
                    <BiBell size={22} className="text-dark mx-2" />
                  </button> */}

                <a
                  href="mailto:asaalimentosuap@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <MdEmail size={22} className="text-dark mx-2" />
                </a>
              </div>
            </div>
          </PanelLogo>
        )}
      </div>

      <div className="row p-2">
        <div className="col-lg-5">
          <CardStudent data={user} />
          <CardChecklist data={checklist} />
        </div>

        <div className="col-lg-7">
          <CardEnrollmentPeriod
            onClick={handleShowQuiz}
            onhandLoadId_enrolled={handLoadId_enrolled}
            onhandLoadDates={handLoadDatesEnroled}
          />

          <CardDelivery checklist={checklist} enrolled_id={enrolledId} />
        </div>
      </div>

      <FooterContainer className="p-2">
        <div className="d-flex align-items-center justify-content-center">
          <h5 className="text-dark">
            Developer by
            <a
              href="mailto:wmachado@kemis.com.br"
              target="_blank"
              rel="noreferrer"
              className="ms-2 text-dark"
            >
              Kemis
            </a>
          </h5>
        </div>
      </FooterContainer>
      <ModalQuiz show={showModalQuiz} onHide={() => setShowModalQuiz(false)} />
    </>
  );
};

export default Students;
