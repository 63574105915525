import styled from 'styled-components';

export const Button = styled.button`
  border-radius: 40px;
  background-color: #cb9614;
  color: #ffffff;
  border: transparent;
  cursor: pointer;
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 1%;
  padding-bottom: 1%;
`;
