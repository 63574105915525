import styled from 'styled-components';

interface IRadioInput {
  selected?: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const RadioInput = styled.label<IRadioInput>`
  display: flex;
  align-items: center;
  margin: 5px 20px 5px 0;

  span {
    color: #828282;
    font-weight: 500;
  }

  > div {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 8px;
    transition-duration: 0.3s;
    background: ${(props) => (props.selected ? '#cb9614' : '#e4f0f9')};
    border: ${(props) =>
      props.selected ? '5px solid #cb9614' : '1px solid #B8B9BA'};
  }

  input {
    display: none;
  }
`;
