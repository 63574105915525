import styled from 'styled-components';

export const PanelLogo = styled.div`
  background-color: #ffffff;

  .logo {
    width: 3rem;
  }

  .title-logo {
    color: #414141;

    @media (max-width: 1440px) {
      font-size: 1.5rem;
    }

    @media (max-width: 425px) {
      font-size: 1.2rem;
    }
  }

  .menu-items {
    @media (max-width: 1440px) {
      font-size: 1.5rem;
    }

    @media (max-width: 425px) {
      font-size: 1rem;
    }
  }
`;

export const FooterContainer = styled.div`
  width: 100%;
  background-color: #ffffff;
`;
