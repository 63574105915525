import styled from 'styled-components';

export const Panel = styled.div`
  border-radius: 10px;
`;

export const SubCard = styled.div`
  color: #ffffff;
  margin: 1%;
  font-size: 12px;

  .container-basket {
    border-radius: 40px;
    background-color: #fc5d4a;
  }

  .container-cookie {
    border-radius: 40px;
    background-color: #ffc107;
  }

  .container-cereal {
    border-radius: 40px;
    background-color: #4caf50;
  }
`;

export const ButtonLink = styled.button`
  border: 0;
  text-decoration: underline;
  color: #cb9614 !important;
  text-decoration-color: #cb9614;
`;
