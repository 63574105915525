import React, { useCallback, useEffect, useState } from 'react';
import Swal from 'sweetalert2';

import { format, parseISO } from 'date-fns';
import api from '~/services/api';

import CardItem from '../CardItems';

import { ButtonLink, Panel, SubCard } from './styles';
import Loading from '~/components/Loading';
import { ICardChecklistResponse } from '../CardChecklist';
import ModalShiftHours from '../../Modals/ModalShiftHours';

export interface ICardDeliveryProps {
  id: number;
  date: string;
  begin_turn: string;
  end_turn: string;
  turn_horary: string;
  basic_basket: number;
  cookie: number;
  cereal: number;
  days_start_turn: number;
  days_end_turn: number;
}

interface IProps {
  checklist: ICardChecklistResponse[];
  enrolled_id: number;
}

const CardDelivery: React.FC<IProps> = ({ checklist, enrolled_id }) => {
  const [deliveries, setDeliveries] = useState<ICardDeliveryProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showTurns, setShowTurns] = useState(false);
  const [deliveryId, setDeliveryId] = useState(0);

  const fillingDelivery = useCallback(async () => {
    setLoading(true);
    const responseDelivery = await api.get<ICardDeliveryProps[]>(
      `students-manager/deliveries`
    );
    setDeliveries(responseDelivery.data);
    setLoading(false);
  }, []);

  useEffect(() => {
    fillingDelivery();
  }, [fillingDelivery]);

  const handleShowTurn = (deliveryData: ICardDeliveryProps) => {
    const validate = checklist.find((item) => item.success === false);
    if (
      (deliveryData.basic_basket && validate?.success === false) ||
      ((deliveryData.cookie || deliveryData.cereal) &&
        validate?.success === false &&
        validate?.id !== 4 &&
        validate?.id !== 5 &&
        !deliveryData.basic_basket)
    ) {
      Swal.fire(
        'Oops...',
        'No te encuentras listo para obtener turno. Revisa los requisitos para ver si te falta algo',
        'info'
      );
    } else {
      setDeliveryId(deliveryData.id);
      setShowTurns(true);
    }
  };

  return (
    <>
      <CardItem cardTitle="Fechas de entrega">
        {deliveries ? (
          deliveries.map((card) => (
            <Panel className="row border mb-2" key={card.date}>
              <div className="col-md-4 p-2">
                <div className="d-flex flex-column">
                  <span className="h6">Fecha de entrega</span>
                  <span className="h6 text-dark">
                    {format(parseISO(card.date), 'dd/MM/yyyy HH:mm')}
                  </span>
                  <div className="d-flex">
                    {card.basic_basket ? (
                      <SubCard>
                        <b className="container-basket px-3 py-1">Bolsón</b>
                      </SubCard>
                    ) : null}
                    {card.cookie ? (
                      <SubCard>
                        <b className="container-cookie px-3 py-1">Galletas</b>
                      </SubCard>
                    ) : null}
                    {card.cereal ? (
                      <SubCard>
                        <b className="container-cereal px-3 py-1">Cereales</b>
                      </SubCard>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-md-2 p-2">
                <div className="d-flex flex-column">
                  <span className="h6">Inicio turno</span>
                  <span className="h6 text-dark">
                    {format(parseISO(card.begin_turn), 'dd/MM/yyyy HH:mm')}
                  </span>
                </div>
              </div>

              <div className="col-md-3 p-2">
                <div className="d-flex flex-column">
                  <div className="h6">Termino turno</div>
                  <span className="h6 text-dark">
                    {format(parseISO(card.end_turn), 'dd/MM/yyyy HH:mm')}
                  </span>
                </div>
              </div>

              <div className="col-md-3 p-2">
                <div className="d-flex flex-column">
                  <span className="h6">Turno</span>
                  {card.turn_horary && (
                    <span className="h6 text-danger">{card.turn_horary}</span>
                  )}

                  {!card.turn_horary &&
                    card.days_start_turn >= 0 &&
                    card.days_end_turn <= 0 &&
                    enrolled_id > 0 && (
                      <div>
                        <ButtonLink
                          className="h6 text-dark"
                          onClick={() => handleShowTurn(card)}
                        >
                          SACAR TURNO
                        </ButtonLink>
                      </div>
                    )}
                </div>
              </div>
            </Panel>
          ))
        ) : (
          <h5>Ninguna fecha disponible.</h5>
        )}
      </CardItem>
      {showTurns && (
        <ModalShiftHours
          show={showTurns}
          enrolled_id={enrolled_id}
          delivery_id={deliveryId}
          onHide={() => setShowTurns(false)}
        />
      )}
      <Loading show={loading} message="Cargando datos del servidor..." />
    </>
  );
};
export default CardDelivery;
