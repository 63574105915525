import React, { useState } from 'react';
import { MdEdit } from 'react-icons/md';
import { Alert } from 'react-bootstrap';
import ModalEditStudent from '../../Modals/ModalEditStudent';
import { User } from '~/hooks/Auth';

import { Panel, Content, SubCard } from './styles';

interface ICardSutendData {
  data: User;
}

const CardStudent: React.FC<ICardSutendData> = ({ data }) => {
  const [show, setShow] = useState(false);
  return (
    <Panel className="d-flex justify-content-center row mx-0 my-2 p-3">
      <div className="row">
        <h5 className="text-dark p-0">Mis datos</h5>
      </div>
      <Content className="row border p-2">
        <div className="row mb-2">
          <div className="col-md-6">
            <div className="h6">Nombre</div>
            <div className="h6 text-dark">{data.name}</div>
          </div>
          <div className="col-md-6">
            <div className="h6">Apellido</div>
            <div className="h6 text-dark">{data.lastname}</div>
          </div>
          <div className="col-md-6">
            <div className="h6">Número del alumno</div>
            <div className="h6 text-dark">{data.enrollment}</div>
          </div>
          <div className="col-md-6">
            <div className="h6">Carreira</div>
            <div className="h6 text-dark">{data.career}</div>
          </div>
          <div className="col-md-6">
            <div className="h6">Celular</div>
            <div className="h6 text-dark">{data.phone}</div>
          </div>
          <div className="col-md-6">
            <div className="h6">Dirección</div>
            <div className="h6 text-dark">{data.road}</div>
          </div>
          <div className="col-md-6">
            <div className="h6">Correo</div>
            <div className="h6 text-dark">{data.email}</div>
          </div>
        </div>

        <div className="col-12">
          <div className="row">
            <div className="col-sm-6 col-sm-6 py-1 d-flex justify-content-center">
              {data.registered ? (
                <SubCard>
                  <div>
                    <span className="container-registered py-1 px-3">
                      Matriculado
                    </span>
                  </div>
                </SubCard>
              ) : (
                <SubCard>
                  <span className="container-notEnrolled py-1 px-3">
                    No matriculado
                  </span>
                </SubCard>
              )}
            </div>
            <div className="col-sm-6 col-sm-6 py-1 d-flex justify-content-center">
              {data.external ? (
                <SubCard>
                  <span className="container-external py-1 px-3">Externo</span>
                </SubCard>
              ) : (
                <SubCard>
                  <span className="container-internal py-1 px-3">Interno</span>
                </SubCard>
              )}
            </div>
          </div>
          <Alert variant="danger" className="my-3 fw-bold">
            <div className="alert">
              Sus datos de matricula terminada y si es externo, seran
              actualizados aproximadamente una semana antes de la primer fecha
              de entrega.
            </div>
          </Alert>
        </div>
      </Content>
      <Content className="col-12">
        <div className="d-flex justify-content-center mt-2">
          <button
            type="button"
            className="btn-edit"
            onClick={() => setShow(true)}
          >
            <div className="d-flex align-items-center">
              <MdEdit size={20} className="me-3" />
              Cambiar datos
            </div>
          </button>
        </div>
      </Content>
      <ModalEditStudent data={data} show={show} onHide={() => setShow(false)} />
    </Panel>
  );
};

export default CardStudent;
