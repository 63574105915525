import styled from 'styled-components';

export const Panel = styled.div`
  font-size: 20px;
  background-color: #ffffff;
  border-radius: 10px;

  div.alert {
    font-size: 12px;
    padding: 5px 5px 0 5px;
  }
`;

export const Content = styled.div`
  border-radius: 10px;

  .btn-edit {
    border-radius: 40px;
    background-color: #cb9614;
    color: #ffffff;
    border: transparent;
    cursor: pointer;
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 1%;
    padding-bottom: 1%;
  }

  .btn-multi {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #41a7fe;
    cursor: pointer;
  }

  .link-multi {
    color: #41a7fe;
  }
`;

export const SubCard = styled.div`
  color: #ffffff;

  span {
    font-weight: bold;
    font-size: 12px;
  }

  .container-registered {
    border-radius: 40px;
    background-color: #195ef7;
  }

  .container-notEnrolled {
    border-radius: 40px;
    background-color: #ff033f;
  }

  .container-external {
    border-radius: 40px;
    background-color: #4caf50;
  }

  .container-internal {
    border-radius: 40px;
    background-color: #ffc107;
  }
`;
