import React, { useRef, useState, useCallback, useEffect } from 'react';
import Lottie from 'react-lottie';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { Link, useHistory } from 'react-router-dom';

import { BsArrowLeftShort } from 'react-icons/bs';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import animation from '~/assets/animations/register.json';
import logo from '~/assets/logos/logo.png';
import { Container, Content } from './styles';

import Input from '~/components/Input';
import Select, { IOption } from '~/components/Select';
import Loading from '~/components/Loading';

interface IStudantProps {
  category_id: number;
  enrollment: string;
  name: string;
  lastname: string;
  career: string;
  doc_type_id: number;
  document: string;
  road: string;
  number: number;
  phone: string;
  email: string;
  password: string;
}

interface IDocumentTypesResponse {
  id: number;
  doc_type: string;
}

const Register: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [activeTab, setActiveTab] = useState('personales');
  const [select, setSelect] = useState<IOption[]>([]);
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);

  const fillingDocumentsType = useCallback(async () => {
    const responseDocumentsType = await api.get<IDocumentTypesResponse[]>(
      'students/document-types'
    );

    const dataDocumentsType = responseDocumentsType.data.map(
      (documentType) => ({
        id: documentType.id,
        value: documentType.doc_type,
        selected: true,
      })
    );
    setSelect(dataDocumentsType);
    return dataDocumentsType;
  }, []);

  useEffect(() => {
    fillingDocumentsType();
  }, [fillingDocumentsType]);

  const handleTabChange = () => {
    if (activeTab === 'personales') {
      setActiveTab('contacto');
    } else {
      setActiveTab('personales');
    }
  };

  const handleSubmit = useCallback(
    async (data: IStudantProps) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        if (activeTab === 'personales') {
          const schema = Yup.object().shape({
            enrollment: Yup.string().required(
              'El numero del alumno es obligatório'
            ),
            name: Yup.string().required('El nombre es obligatório'),
            lastname: Yup.string().required('El apellido es obligatório'),
            career: Yup.string().required('La carrera es obligatória'),
            document: Yup.string().required(
              'El numero de documento es obligatório'
            ),
            doc_type_id: Yup.string().required(
              'El tipo de documento es obligatório'
            ),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          setActiveTab('contacto');
        } else {
          const schema = Yup.object().shape({
            email: Yup.string().required('El correo es obligatório'),
            password: Yup.string().required('La contraseña es obligatória'),
            phone: Yup.string().required('El telefono es obligatório'),
            road: Yup.string().required('La calle es obligatória'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          await api.post(`students-manager/students`, data);

          Swal.fire(
            'Registro realizado en forma correcta.',
            'Te va llegar un correo para que puedas habilitar tu aceso al sistema.',
            'success'
          );
          history.push('/');
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire('Opss...', 'Erro al hacer su registro', 'error');
        }
      }
      setLoading(false);
    },
    [activeTab, history]
  );

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Container className="container-fluid">
      <div className="row">
        <Content className="col-lg-1 mt-3 mb-5">
          <div className="row">
            <Link to={`${process.env.PUBLIC_URL}/`}>
              <BsArrowLeftShort size={30} className="btn-edit" />
            </Link>
          </div>
        </Content>
        <Content className="col-lg-7 d-flex flex-column justify-content-center align-items-center">
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Tabs
              activeKey={activeTab}
              onSelect={() => handleTabChange}
              className="nav-personalizate"
            >
              <Tab eventKey="personales" title="Datos personales" disabled>
                <div className="row mb-5 mt-5">
                  <h1 className="fw-bold">Registrar</h1>
                  <h6>Complete todos los datos</h6>
                </div>
                <div className="row mb-1">
                  <div className="col-md-6">
                    <label htmlFor="enrollment" className="mb-2 fw-medium">
                      Numero de alumno
                    </label>
                    <Input type="number" name="enrollment" />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="career" className="mb-2 fw-medium">
                      Carrera
                    </label>
                    <Input name="career" />
                  </div>
                  <div className="col-md-6 mt-3">
                    <label htmlFor="name" className="mb-2 fw-medium">
                      Nombre
                    </label>
                    <Input type="name" name="name" />
                  </div>
                  <div className="col-md-6 mt-3">
                    <label htmlFor="lastname" className="mb-2 fw-medium">
                      Apellido
                    </label>
                    <Input name="lastname" />
                  </div>
                  <div className="col-md-6 mt-3">
                    <label htmlFor="doc_type_id" className="mb-2 fw-medium">
                      Tipo de documento
                    </label>
                    <Select name="doc_type_id" options={select} />
                  </div>
                  <div className="col-md-6 mt-3">
                    <label htmlFor="document" className="mb-2 fw-medium">
                      Numero de documento
                    </label>
                    <Input name="document" />
                  </div>
                </div>
                <div className="button-personalizate border mt-5">
                  <button
                    type="submit"
                    className="btn btn-submit w-100 fw-bold py-3"
                  >
                    Próximo
                  </button>
                </div>
              </Tab>
              {/** SECOND TAB */}
              <Tab eventKey="contacto" disabled title="Detalles de contacto">
                <div className="row mb-5 mt-5">
                  <h1 className="fw-bold">Registrar</h1>
                  <h6>Complete todos los datos</h6>
                </div>
                <div className="row mb-1">
                  <div className="mb-4">
                    <label htmlFor="email" className="mb-2 fw-medium">
                      Correo
                    </label>
                    <Input name="email" />
                  </div>
                </div>

                <div className="row mb-1">
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label htmlFor="password" className="mb-2 fw-medium">
                        Contraseña
                      </label>
                      <Input type="password" name="password" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label htmlFor="phone" className="mb-2 fw-medium">
                        Teléfone
                      </label>
                      <Input name="phone" />
                    </div>
                  </div>
                </div>

                <div className="row mb-1">
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label htmlFor="road" className="mb-2 fw-medium">
                        Calle
                      </label>
                      <Input name="road" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label htmlFor="number" className="mb-2 fw-medium">
                        Número
                      </label>
                      <Input type="number" name="number" />
                    </div>
                  </div>
                </div>

                <div className="row mb-5">
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label htmlFor="road" className="mb-2 fw-medium">
                        Casa/Departamento
                      </label>
                      <Input name="complement" />
                    </div>
                  </div>
                </div>
                <div className="button-personalizate border">
                  <button
                    type="submit"
                    className="btn btn-submit w-100 fw-bold py-3"
                  >
                    Registrar
                  </button>
                </div>
              </Tab>
            </Tabs>
          </Form>
        </Content>
        <Content className="col-lg-4 d-none d-lg-flex flex-column justify-content-center px-0">
          <div className="d-flex align-items-end flex-column flex-column h-100 row">
            <div className="row me-4">
              <div className="d-flex mt-4 flex-row-reverse">
                <img src={logo} alt="logo" className="logo" />
              </div>
            </div>
            <div className="row mt-auto mb-2">
              <div className="d-flex align-items-end">
                <Lottie options={defaultOptions} height={400} width={400} />
              </div>
            </div>
          </div>
        </Content>
      </div>
      <Loading show={loading} message="Gardando datos..." />
    </Container>
  );
};

export default Register;
