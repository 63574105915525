import React, { useRef, useCallback, useState, useEffect } from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import Swal from 'sweetalert2';

import { Alert } from 'react-bootstrap';
import api from '~/services/api';

import { Modal } from './styles';

import InputCheckbox, { IOptionCheckbox } from '~/components/InputCheckbox';
import InputRadio from '~/components/InputRadio';
import Textarea from '~/components/Textarea';
import Loading from '~/components/Loading';
import Select, { IOption } from '~/components/Select';
import Input from '~/components/Input';

interface ITestPersonalProps {
  marital_status: number;
  coexistence_group: string;
  people_house: number;
  basic_basket: number;
  cookies: number;
  cereals: number;
  familiar_help: number;
  way_familiar_help: number;
  another_help: number;
  necesity_scale: number;
  necesity_description: string;
}

interface IMaritalStatusResponse {
  id: number;
  marital_status: string;
}

interface ITesteData {
  show: boolean;
  onHide: () => void;
}

const ModalQuiz: React.FC<ITesteData> = ({ show, onHide }) => {
  const formRef = useRef<FormHandles>(null);
  const [options, setOptions] = useState<IOptionCheckbox[]>([]);
  const [optionsSelected, setOptionsSelected] = useState<IOptionCheckbox[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [maritalStatus, setMaritalStatus] = useState<IOption[]>([]);

  useEffect(() => {
    setOptions([
      { value: 1, label: 'Galletas', selected: false },
      { value: 1, label: 'Cereales', selected: false },
      { value: 1, label: 'Bolsón', selected: false },
    ]);
  }, [show]);

  const handleMaritalStatus = useCallback(async () => {
    const maritalStatusResponse = await api.get<IMaritalStatusResponse[]>(
      'students-manager/marital-status'
    );

    setMaritalStatus(
      maritalStatusResponse.data.map((documentType) => ({
        id: documentType.id,
        value: documentType.marital_status,
        selected: false,
      }))
    );
  }, []);

  useEffect(() => {
    handleMaritalStatus();
  }, [handleMaritalStatus]);

  const handleChange = (option: IOptionCheckbox[]) => {
    setOptionsSelected(option.filter((item) => item.selected === true));
  };

  const handleClose = () => {
    onHide();
  };

  const handleSubmit = useCallback(
    async (data: ITestPersonalProps) => {
      setLoading(true);
      try {
        const {
          marital_status,
          coexistence_group,
          people_house,
          familiar_help,
          way_familiar_help,
          another_help,
          necesity_scale,
          necesity_description,
        } = data;

        const formData = {
          marital_status_id: marital_status,
          coexistence_group,
          people_house,
          basic_basket: optionsSelected[0] ? optionsSelected[0].value : 0,
          cookies: optionsSelected[1] ? optionsSelected[1].value : 0,
          cereals: optionsSelected[2] ? optionsSelected[2].value : 0,
          familiar_help,
          way_familiar_help,
          another_help,
          necesity_scale,
          necesity_description,
        };

        await api.post(`students-manager/enrolleds`, formData);
        onHide();
        window.location.reload();
      } catch (error) {
        Swal.fire('Opss...', 'Erro al guardar los datos', 'error');
      }
      setLoading(false);
    },
    [onHide, optionsSelected]
  );

  return (
    <>
      <Modal show={show} onHide={handleClose} close size="lg">
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Modal.Header className="border-none p-3">
            <div className="row w-50">
              <b className="h5 text-dark">Encuesta</b>
            </div>
            <div className="row w-10">
              <button
                type="button"
                className="h3 border-none"
                onClick={handleClose}
              >
                X
              </button>
            </div>
          </Modal.Header>
          <Modal.Body className="p-3">
            <div className="row">
              <div className="col-md-2 mb-3">
                <label htmlFor="marital_status" className="mb-2 fw-medium">
                  Estado civil
                </label>
                <Select
                  name="marital_status"
                  options={maritalStatus}
                  onChangeText={handleMaritalStatus}
                />
              </div>
              <div className="col-md-5 mb-3">
                <label htmlFor="coexistence_group" className="mb-2 fw-medium">
                  Grupo de convivencia (con quien vive)
                </label>
                <Input name="coexistence_group" />
              </div>
              <div className="col-md-5 mb-3">
                <label htmlFor="people_house" className="mb-2 fw-medium">
                  Cuantos componen el nucleo familiar
                </label>
                <Input type="number" name="people_house" />
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-4">
                <label htmlFor="supplies">
                  Selecciona lo que deseas recibir:
                </label>
              </div>
              <div className="col-md-8 d-flex justify-content-start">
                <InputCheckbox
                  name="supplies"
                  options={options}
                  onChange={handleChange}
                  className="d-flex flex-column flex-md-row"
                />
              </div>
            </div>
            <hr />
            <div className="row mb-2">
              <div className="row">
                <Alert key="warning" variant="warning">
                  <span className="fw-bold">
                    A continuación te haremos unas preguntas para poder conocer
                    tu situación actual con respecto a los alimentos, realizamos
                    este tipo de encuestas debido a la alta demanda y limitadas
                    donaciones que recibimos.
                  </span>
                </Alert>
              </div>
            </div>

            <hr />
            <div className="row">
              <div className="d-flex flex-column flex-md-row">
                <label htmlFor="familiar_help" className="me-3">
                  Recibes ayuda de un familiar para los alimentos:
                </label>
                <InputRadio
                  name="familiar_help"
                  options={[
                    { value: '0', label: 'No' },
                    { value: '1', label: 'Sí' },
                  ]}
                />
              </div>
            </div>

            <hr />
            <div className="row">
              <div className="d-flex flex-column flex-md-row">
                <label htmlFor="way_familiar_help" className="me-3">
                  Es de forma:
                </label>
                <InputRadio
                  name="way_familiar_help"
                  options={[
                    { value: 0, label: 'Parcial' },
                    { value: 1, label: 'Completa' },
                  ]}
                />
              </div>
            </div>

            <hr />
            <div className="row mb-2">
              <div className="d-flex flex-column flex-md-row">
                <label htmlFor="another_help" className="me-3">
                  Recibes donación de algún feligrés, iglesia o grupo misionero:
                </label>
                <InputRadio
                  name="another_help"
                  options={[
                    { value: 0, label: 'No' },
                    { value: 1, label: 'Sí' },
                  ]}
                />
              </div>
            </div>

            <hr />
            <div className="row">
              <div className="row">
                <label htmlFor="necesity_scale">
                  En una escala del 1 al 5 enumera la necesidad que tienes
                  actualmente de los alimentos (siendo 1 poca necesidad y 5
                  mucha necesidad).
                </label>
                <div className="d-flex">
                  <InputRadio
                    name="necesity_scale"
                    options={[
                      { value: 1, label: '1' },
                      { value: 2, label: '2' },
                      { value: 3, label: '3' },
                      { value: 4, label: '4' },
                      { value: 5, label: '5' },
                    ]}
                  />
                </div>
              </div>
            </div>

            <hr />
            <div className="row mb-2">
              <div className="row">
                <span>
                  Te dejamos un espacio para que puedas describir algunos
                  aspectos que consideres importante que debamos tener en cuenta
                  con respecto a tu situación actual de los alimentos, para así
                  poder evaluar mejor tu solicitud.
                </span>
                <div className="row">
                  <Textarea name="necesity_description" className="m-2 h-100" />
                </div>
              </div>
            </div>
            <div className="row mb-2 mt-5">
              <div className="d-flex btn-position">
                <button
                  type="submit"
                  className="btn btn-submit fw-bold px-3 py-1"
                >
                  Guardar
                </button>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-none d-flex" />
        </Form>
        <Loading show={loading} message="Guardando los datos..." />
      </Modal>
    </>
  );
};

export default ModalQuiz;
