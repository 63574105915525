import React, { useCallback, useState, useEffect } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import axios from 'axios';

import Swal from 'sweetalert2';

import { Alert } from 'react-bootstrap';
import api from '~/services/api';

import { Modal } from './styles';
import Loading from '~/components/Loading';

export interface IShift {
  delivery_id: number;
  description: string;
  quantity: number;
  hour: number;
}

interface IShifts {
  show: boolean;
  enrolled_id: number;
  delivery_id: number;
  onHide: () => void;
}

interface IResponse {
  message?: string;
}

const ModalShiftSchedule: React.FC<IShifts> = ({
  show,
  onHide,
  enrolled_id,
  delivery_id,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [shifts, setShifts] = useState<IShift[]>([]);

  const fillingPeriods = useCallback(async () => {
    setLoading(true);
    const responseShift = await api.get<IShift>(
      `students-manager/deliveries/${delivery_id}/turns`
    );

    const newResponseShift = Object.values(responseShift.data);

    setShifts(newResponseShift);
    setLoading(false);
    return responseShift;
  }, [delivery_id]);

  useEffect(() => {
    fillingPeriods();
  }, [fillingPeriods]);

  const handleSaveShift = useCallback(
    async (dataShift: IShift) => {
      setLoading(true);
      try {
        const { hour } = dataShift;
        const newDataShift = {
          hour,
          enrolled_id,
        };
        await api.post(
          `students-manager/deliveries/${delivery_id}/turns`,
          newDataShift
        );
        onHide();
        window.location.reload();
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const response = error?.response?.data as IResponse;
          Swal.fire('Opss...', response.message, 'error');
        } else {
          Swal.fire('Opss...', 'No fue posible agendar su turno.', 'error');
        }
      }
      setLoading(false);
    },
    [enrolled_id, delivery_id, onHide]
  );

  const handleSelectShift = useCallback(
    (dataShift: IShift) => {
      Swal.fire({
        text: `Has elegido el turno ${dataShift.description}. Estás seguro?`,
        icon: 'question',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sí, estoy seguro',
        confirmButtonColor: '#cb9614',
        cancelButtonColor: '#d3d3d3',
        cancelButtonText: 'No',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          handleSaveShift(dataShift);
        }
      });
    },
    [handleSaveShift]
  );

  return (
    <>
      <Modal show={show} onHide={onHide} close size="md">
        <Modal.Header className="border-none p-5 pb-4">
          <div className="row w-50">
            <b className="h4 text-dark">Turnos</b>
          </div>
          <div className="row w-10">
            <button type="button" className="h3 border-none" onClick={onHide}>
              X
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className="p-5 pb-2 pt-0">
          <div className="row mb-3">
            {!loading && shifts.length === 0 && (
              <Alert variant="warning">
                <div className="d-flex justify-content-center">
                  <b className="mt-2">
                    No hay turnos disponibles para esta entrega.
                  </b>
                </div>
              </Alert>
            )}
            <ListGroup as="ol">
              {shifts.map((period) => (
                <ListGroup.Item
                  as="li"
                  key={period.hour}
                  className="d-flex justify-content-between align-items-center list-content"
                  onClick={() => handleSelectShift(period)}
                >
                  <div className="">
                    <div className="h3 fw-bold mt-2">{period.description}</div>
                  </div>
                  <div className="badge h6 bg-warning badge-content mt-1">
                    {period.quantity}
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>

          <div className="row">
            <Alert variant="info">
              <div className="d-flex justify-content-center">
                <b className="mt-2">
                  Favor fijate que horario te asignó el sistema, además te va a
                  llegar un correo con los datos de tu turno asginado.
                </b>
              </div>
            </Alert>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none d-flex" />
        <Loading show={loading} message="Buscando los turnos..." />
      </Modal>
    </>
  );
};

export default ModalShiftSchedule;
