import styled from 'styled-components';

export const PanelData = styled.div`
  font-size: 12px;
  background-color: #ffffff;
  border-radius: 10px;

  .border-inscripicion {
    border-radius: 10px;
  }

  .button-position {
    justify-content: end;

    @media (max-width: 768px) {
      justify-content: center;
    }
  }
`;

export const PanelScroll = styled.div`
  border-radius: 10px;
  overflow-x: scroll;
`;
