import React, { useCallback, useRef, useState, useEffect } from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';
import { useAuth, User } from '~/hooks/Auth';

import Input from '~/components/Input';
import Select, { IOption } from '~/components/Select';

import { Modal } from './styles';

interface IModalEditData {
  data: User;
  show: boolean;
  onHide: () => void;
}
interface IDocumentTypesResponse {
  id: number;
  doc_type: string;
}

const ModalEditStudent: React.FC<IModalEditData> = ({ data, show, onHide }) => {
  const formRef = useRef<FormHandles>(null);
  const [selects, setSelects] = useState<IOption[]>([]);
  const { updateUser } = useAuth();

  const fillingDocumentsType = useCallback(async () => {
    const responseDocumentsType = await api.get<IDocumentTypesResponse[]>(
      'students/document-types'
    );

    return responseDocumentsType.data.map((documentType) => ({
      id: documentType.id,
      value: documentType.doc_type,
      selected: false,
    }));
  }, []);

  const handleShow = useCallback(async () => {
    const responseSelects = await fillingDocumentsType();

    if (responseSelects.length > 0) {
      responseSelects[1].selected = true;
      setSelects(responseSelects);
    }
  }, [fillingDocumentsType]);

  useEffect(() => {
    if (show) {
      handleShow();
    }
  }, [handleShow, show]);

  const onClose = useCallback(() => {
    const newDocumentsType = selects.map((selectType) => {
      return {
        ...selectType,
        selected: false,
      };
    });
    onHide();
    setSelects(newDocumentsType);
  }, [onHide, selects]);

  const handleSubmit = useCallback(
    async (dataStudent: User) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          career: Yup.string().required('Obligatório informar la carrera'),
          email: Yup.string().required('Obligatorio informar el email'),
          phone: Yup.string().required('Obligatorio informar el teléfono'),
          doc_type_id: Yup.string().required(
            'Obligatorio informar el tipo de documento'
          ),
          document: Yup.string().required('Obligatorio informar el documento'),
          road: Yup.string().required('Obligatorio informar la calle'),
          number: Yup.string().required('Obligatorio informar el número'),
        });

        await schema.validate(dataStudent, {
          abortEarly: false,
        });

        await api
          .put(`students-manager/students`, dataStudent)
          .then((request) => {
            const dataStudentUpdate = {
              id: request.data.id,
              enrollment: request.data.enrollment,
              name: request.data.name,
              lastname: request.data.lastname,
              career: request.data.career,
              doc_type_id: request.data.doc_type_id,
              document: request.data.document,
              email: request.data.email,
              phone: request.data.phone,
              road: request.data.road,
              external: request.data.external,
              registered: request.data.registered,
              number: request.data.number,
              complement: request.data.complement,
            };
            updateUser(dataStudentUpdate);
          });

        onHide();
        Swal.fire('Éxito', 'Datos cambiados con éxito', 'success');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire('Opss...', 'Error al intentar cambiar los datos', 'error');
        }
      }
    },
    [onHide, updateUser]
  );
  return (
    <>
      <Modal show={show} onHide={onClose} close size="xl">
        <Form ref={formRef} initialData={data} onSubmit={handleSubmit}>
          <Modal.Header className="border-none p-5 pb-4">
            <div className="row w-50">
              <b className="h5 text-dark">Cambiar datos</b>
            </div>
            <div className="row w-10">
              <button type="button" className="h3 border-none" onClick={onHide}>
                X
              </button>
            </div>
          </Modal.Header>
          <Modal.Body className="p-5 pb-2 pt-0">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="email" className="mb-2 fw-medium">
                  Correo
                </label>
                <Input name="email" />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="phone" className="mb-2 fw-medium">
                  Teléfono
                </label>
                <Input name="phone" />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="career" className="mb-2 fw-medium">
                  Carrera
                </label>
                <Input name="career" />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="doc_type_id" className="mb-2 fw-medium">
                  Tipo de documento
                </label>
                <Select
                  name="doc_type_id"
                  options={selects}
                  onChangeText={handleShow}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="document" className="mb-2 fw-medium">
                  Número de documento
                </label>
                <Input placeholder="seu documento" name="document" />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="road" className="mb-2 fw-medium">
                  Dirección
                </label>
                <Input name="road" />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="number" className="mb-2 fw-medium">
                  Numero
                </label>
                <Input type="number" name="number" />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="complement" className="mb-2 fw-medium">
                  Casa/Dpto
                </label>
                <Input type="text" name="complement" />
              </div>
            </div>
            <div className="row mt-2">
              <div className="d-flex btn-position">
                <button
                  type="submit"
                  className="btn btn-submit fw-bold px-4 py-1"
                >
                  Guardar
                </button>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-none d-flex" />
        </Form>
      </Modal>
    </>
  );
};

export default ModalEditStudent;
