import React, { useCallback, useRef } from 'react';
import Lottie from 'react-lottie';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import { useHistory, useParams } from 'react-router-dom';
import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';
import logo from '~/assets/logos/logo.png';
import animation from '~/assets/animations/login.json';

import { Container, Content } from './styles';
import Input from '~/components/Input';

interface IFormData {
  password: string;
  password_confirmation: string;
}

interface IParams {
  token: string;
}

const ForgotPassword: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const params = useParams<IParams>();

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          password: Yup.string().required('La contraseña es obligatoria'),
          password_confirmation: Yup.string().when('password', {
            is: (passwordData: string) =>
              passwordData && passwordData.length > 0,
            then: Yup.string().oneOf(
              [Yup.ref('password'), undefined],
              'Las contraseñas no coinciden'
            ),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { password } = data;

        const formData = {
          token: params.token,
          password,
        };

        await api.post(`students-manager/reset-password`, formData);

        Swal.fire(
          '¡Muy bien!',
          'La contraseña ha sido cambiada con éxito.',
          'success'
        ).then(() => {
          history.push(process.env.PUBLIC_URL);
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Se ha producido un error al cambiar la contraseña, por favor, inténtelo de nuevo.',
            'error'
          );
        }
      }
    },
    [history, params.token]
  );

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Container className="container-fluid">
      <div className="row">
        <div className="col-lg-5 d-none d-lg-flex flex-column justify-content-center px-0">
          <Lottie options={defaultOptions} height={400} width={400} />
        </div>
        <Content className="col-lg-7 d-flex flex-column justify-content-center align-items-center">
          <div className="row w-100">
            <div className="d-flex mt-2 mb-5 flex-row-reverse justify-content-center">
              <img src={logo} alt="logo" className="logo" />
            </div>
          </div>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            className="px-3 px-sm-4 px-md-5 px-lg-0"
          >
            <div>
              <h1 className="fw-bold h2 mb-3">
                Establecer una nueva contraseña
              </h1>
              <p className="mb-5">Por favor, introduzca su nueva contraseña</p>
            </div>
            <div className="mb-5">
              <div>
                <label htmlFor="password" className="mb-2 fw-medium">
                  Nueva contraseña:
                </label>
                <Input type="password" name="password" />
              </div>
              <div>
                <label
                  htmlFor="password_confirmation"
                  className="mt-3 mb-2 fw-medium"
                >
                  Confirmar contraseña:
                </label>
                <Input type="password" name="password_confirmation" />
              </div>
            </div>
            <div className="mb-3">
              <button
                type="submit"
                className="btn btn-submit mb-2 w-100 fw-bold py-3"
              >
                Enviar
              </button>
            </div>
            <div className="d-flex px-5 pb-5 justify-content-center align-items-center">
              <img src={logo} alt="logo" className="logo" />
            </div>
          </Form>
        </Content>
      </div>
    </Container>
  );
};

export default ForgotPassword;
