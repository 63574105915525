import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Modal = styled(ModalComponent)`
  .modal-content {
    background: #ffffff;
  }

  .btn-submit {
    color: #ffffff;
    background: #2e90fd;
    border-radius: 40px;
  }

  .nav-personalizate {
    display: none;
  }

  .list-content {
    cursor: pointer;
  }

  .badge-content {
    color: black;
  }
`;
