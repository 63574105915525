import React, { useCallback, useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useHistory, useParams } from 'react-router-dom';

import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';
import logo from '~/assets/logos/logo.png';
import animation from '~/assets/animations/login.json';

import Loading from '~/components/Loading';
import { Container } from './styles';

interface IParams {
  email: string;
  token: string;
}

const ForgotPassword: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const params = useParams<IParams>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleValidEmail = useCallback(async () => {
    try {
      const formData = {
        token: params.token,
        email: params.email,
      };

      await api.post(`students-manager/activate`, formData);

      Swal.fire(
        '¡Muy bien!',
        'Tu usuario está activado. Ya podés acceder al sistema.',
        'success'
      ).then(() => {
        history.push('/');
      });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErros(error);
        formRef.current?.setErrors(errors);
      } else {
        Swal.fire(
          'Opss...',
          'El token ya no es más, válido. Intentar de vuelta hacer tu registro.',
          'error'
        ).then(() => {
          history.push('/');
        });
      }
    }
  }, [history, params.token, params.email]);

  useEffect(() => {
    setLoading(true);
    handleValidEmail().finally(() => setLoading(false));
  }, [handleValidEmail]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Container className="container-fluid">
      <div className="d-flex align-items-end flex-column flex-column h-100 row">
        <div className="row">
          <div className="d-none d-lg-flex flex-column justify-content-center px-0">
            <img src={logo} alt="logo" className="logo" />
          </div>
        </div>
        <div className="row">
          <div className="d-none d-lg-flex flex-column justify-content-center px-0">
            <Lottie options={defaultOptions} height={400} width={400} />
          </div>
        </div>
      </div>
      <Loading show={loading} message="Validando datos..." />
    </Container>
  );
};

export default ForgotPassword;
